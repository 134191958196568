<template>
  <main>
    <div class="status_wrapper">
   <!--   <Form
        id="form"
        :form_id="status_form_id"
        :uid="uid"
        @on_error="viewError"
         :hideError="hiderror"
        :submission_id="submission_id"
        :server="server"
        v-on:results="getResult"
      /> -->

      <div v-if="valid">
        <h3>
          Solde restant : <span>{{ amount }}€</span>
        </h3>


<h2>Cette offre est à présent terminée</h2>
<br><br>

        <div v-if="items.length <= 0" class="achats">
          <div>Vous n'avez pas déclaré d'achat</div>
        </div>

        <div v-if="items.length > 0" class="achats">
          <div class="facture headtable">
            <div class="first">Accessoires ou Services</div>
            <div class="middle">Date d'enregistrement</div>
            <div class="lastcol">Status / Somme en €</div>
          </div>

          <div>
            <div class="facture" v-for="item in items" :key="item.invoice">
              <div class="first">
                <ul>
                  <li v-for="product in item.prix_produits" :key="product.index">
                    {{ product.produit}}<br>
                    <span><b>Quantité :</b>  {{ product.quantity}}</span>
                    <span><b>Prix total :</b>  {{ (product.quantity*(product.price / 100)).toFixed(2) }}€</span>
                    </li> 
                </ul>
                <a :href="item.invoice" target="_blank">{{
                  item.invoice | filename
                }}</a>
              </div>
              <div class="middle">Le {{ item.updatedAt | newDate }}</div>
              <div
                class="lastcol"
                v-if="item.status == 'PENDING'"
                style="text-align:left;"
              >
                En attente
              </div>
              <div
                class="lastcol"
                v-if="item.status == 'INVALID'"
                style="text-align:left;"
              >
                Non conforme - {{ item.motif_nc }}
              </div>
              <div
                class="lastcol"
                v-if="item.status == 'VALID'"
                style="text-align:left;"
              >
                {{ item.status | status(item.points) }}
                <span v-if="item.points">&nbsp;/ {{ (item.points/100).toFixed(2) }} €</span>
              </div>
              <div
                class="lastcol"
                v-if="item.status == 'REFUND_DONE'"
                style="text-align:left;"
              >
                {{ item.status | status(item.points) }}
                <span v-if="item.points">&nbsp;/ {{ (item.points/100).toFixed(2) }} €</span>
              </div>
              <div
                class="lastcol"
                v-if="item.status == 'AUTOMATIC'"
                style="text-align:left;"
              >
                {{ item.status | status(item.points) }}
                <span v-if="item.points">&nbsp;/ {{ item.points }} €</span>
              </div>
              <div
                class="lastcol col-left"
                style="text-align:left;"
                v-if="item.status == 'MISSING'"
              >
                <div v-if="item.motif_nc && item.motif_nc !== 'Autre'">
                  {{ item.motif_nc }}
                </div>
                <div
                  v-if="item.validation_comment"
                  :class="{ italic: item.motif_nc == 'autre' }"
                >
                  {{ item.validation_comment }}
                </div>
                <span class="small"
                  >Cliquez sur le bouton "Déclarer un achat" afin de régulariser votre dossier</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <div>
         <!--   <router-link
              tag="button"
              class="upload-btn"
              :to="`/upload/${submission_id}/${uid}`"
              v-if="declared_button===true"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                ></path>
              </svg>
              Déclarer un achat
            </router-link> -->
          </div>
          <div v-if="questionnaire">
            <button type="button" class="upload-btn disabled">
              Vous avez répondu au questionnaire
            </button>
          </div>

          <div v-if="!questionnaire">
            <router-link
              tag="button"
              :to="`/questionnaire/${submission_id}/${uid}`"
              type="button"
              class="upload-btn"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                ></path>
              </svg>
              Répondre au questionnaire
            </router-link>
   
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Vue from "vue";
import axios from "axios";
let consts = require("../config");
const moment = require("moment");

Vue.filter("filename", function(url) {
  if (!url) {
    return "";
  }
  var m = url.toString().match(/.*\/(.+?)\./);
  if (m && m.length > 1) {
    return m[1];
  }
});

Vue.filter("status", function(status, points) {
  if (status == "AUTOMATIC" && points > 0) {
    return "Annulation commande";
  }
  return {
    PENDING: "En attente",
    VALID: "Validé",
    MISSING: "Echec",
    INVALID: "Non conforme",
    REFUND_DONE: "Remboursement effectué",
    AUTOMATIC: "Dépense effectuée",
  }[status];
});

Vue.filter("newDate", function(date) {
  var m = moment(date);
  return m.format("DD/MM/YYYY");
});

export default {
  name: "Status",
  data: function() {
    return {
      items: [],
      declared_button : false,
      hiderror: true,
      submission_id: this.$route.params._id,
      form_id: consts.QUESTIONNAIRE_ID,
      status_form_id: consts.STATUS_ID,
      server: "https://api.lucien.ai",
      uid: this.$route.params._secu,
      questionnaire: false,
      gender: undefined,
      last_name: undefined,
      valid: false,
      amount: 0,
    };
  },
  async mounted() {
    try {
      let { data } = await axios.get(
        "https://api.lucien.ai/api/v2/submissions/" +
          this.$route.params._id +
          "?uid=" +
          this.$route.params._secu
      );

      if (data.participationValide === "VALID") this.valid = true;
      if (data.question_1) this.questionnaire = true;

      this.gender = data.gender;
      this.last_name = data.last_name;
      this.items = data.lineItems;

      /* CALCUL DU MONTANT A DEDUIRE */
      if (data.amount_to_refund == undefined || data.amount_to_refund < 0 || !data.amount_to_refund ) {
        this.amount = 0;
      } else {
        this.amount = data.amount_to_refund ;
      }


      if (data.lineItems) {
        let total_amount = 0;
        for (const line in data.lineItems) {
          if(!data.lineItems[line].points) data.lineItems[line].points=0
          total_amount += data.lineItems[line].points;
        }



        new_amount = (this.amount-total_amount);
      } else {
        var new_amount = 0;
      }

 

      this.amount = new_amount / 100;
      if(this.amount  > 0) this.declared_button = true; 
      
      

    } catch (err) {
      console.log(err);
      this.$swal({
        type: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        confirmButtonColor: "#C94330",
        html:
          "Identifiants non reconnu.<br > Merci de suivre le lien transmit par email.",
      }).then(function() {
        document.location.href = "/";
      });
      return;
    }
  },
  methods: {
    viewError(err) {
      console.log(err)
    
            this.$swal({
            confirmButtonText: 'OK',
            confirmButtonColor: '#C94330',
            title: 'Désolé, une erreur est survenue.',
            text: ""
          }).then(function () {
            window.location.href = "/";
          });
        
    },     
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          allowOutsideClick: false,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    getResult: function(result) {
      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          html:
            "<b>Votre status a bien été mises à jour. Vous serez informé de sa validation par email.</b>",
        }).then((result) => {
          this.$router.push("/");
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          text: "Une erreur est survenue",
        });
      }
    },
  },
};
</script>
